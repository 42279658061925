/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/prop-types */
/*eslint-disable*/

import Link from "Component/Link";
import "./CategoryNavRight.scss";

export function CategoryNavRight({ category, closeMenu }) {
    const { children = {} } = category;

    const subCategories = Object.values(children);
    //  console.log(subCategories);

    return (
        <div className="CategoryRight">
            {subCategories.map((sC, idx) => {
                const { children = {} } = sC;
                const subLinks = Object.values(children);
                return (
                    <div className="CategoryRight-Titles" key={idx}>
                        <Link
                            onClick={() => {
                                // window.location.href = sC.url.pathname;
                                // history.push(sC.url.pathname);
                                closeMenu();
                            }}
                            to={sC.url.pathname}
                            block="CategoryRight"
                            elem="Titles-Name"
                            // className="CategoryRight-Titles-Name"
                        >
                            {sC.title}
                        </Link>
                        <div className="CategoryRight-Titles-Description">
                            {subLinks.map((link, i) => (
                                <Link
                                    key={i}
                                    block="CategoryRight"
                                    elem="Titles-Description-Link"
                                    onClick={() => {
                                        // history.push(link.url.pathname);
                                        closeMenu();
                                    }}
                                    to={link.url.pathname}
                                >
                                    {link.title}
                                </Link>
                            ))}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default CategoryNavRight;
