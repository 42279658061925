/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';

import Link from 'Component/Link';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { getSortedItems } from 'Util/Menu';

import './MobileMenu.scss';

export function handleExpantion(setExpand, index) {
    setExpand((prevState) => {
        if (prevState.includes(index)) {
            return prevState.filter((idx) => idx !== index);
        }

        return [...prevState, index];
    });
}

export function checkExpantion(expand, index) {
    if (expand.includes(index)) {
        return true;
    }

    return false;
}

export function expandableIcons(bool) {
    if (bool) {
        return (
            <span>
                <svg
                  className="all-level-link-arrow-up"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 15l7-7 7 7"
                    />
                </svg>
            </span>
        );
    }

    return (
        <svg
          className="all-level-link-arrow-up"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
        </svg>
    );
}

export function RenderThirdLevel({ thirdLevel, toggleMobileMenu }) {
    const {
        title,
        url: { pathname }
    } = thirdLevel;

    return (
        <li>
            <Link
              block="menu"
              elem="item-third-level-link"
              to={ pathname }
              onClick={ toggleMobileMenu }
            >
                { title }
            </Link>
        </li>
    );
}

export function RenderSecondLevel({ secondLevel, idx, toggleMobileMenu }) {
    const [expandedList, addToExpanded] = useState([]);

    const {
        title,
        children,
        url: { pathname }
    } = secondLevel;

    const thirdLevelItems = Object.values(children);
    const noNextLevel = thirdLevelItems.length === 0;

    return (
        <>
            <li>
                <a
                  block="menu"
                  elem="item-second-level-link"
                  onClick={ () => {
                      if (noNextLevel) {
                          history.push(pathname);
                          return toggleMobileMenu();
                      }
                      handleExpantion(addToExpanded, idx);
                  } }
                >
                    { title }
                    { !noNextLevel
                        && expandableIcons(checkExpantion(expandedList, idx)) }
                </a>
            </li>

            { checkExpantion(expandedList, idx) && (
                <ul className="menu-item-third-level">
                    { thirdLevelItems.map((thirdLevel) => (
                        <RenderThirdLevel
                          key={ thirdLevel.item_id }
                          thirdLevel={ thirdLevel }
                          toggleMobileMenu={ toggleMobileMenu }
                        />
                    )) }
                </ul>
            ) }
        </>
    );
}

export function RenderFirstLevel({ firstLevel, idx, toggleMobileMenu }) {
    const [expandedList, addToExpanded] = useState([]);
    const {
        children,
        title,
        url: { pathname }
    } = firstLevel;
    const secondLevelItems = Object.values(children);
    const noNextLevel = secondLevelItems.length === 0;

    return (
        <li className="menu-item-first-level">
            <a
              block="menu"
              elem="item-first-level-link"
              onClick={ () => {
                  if (noNextLevel) {
                      history.push(pathname);
                      return toggleMobileMenu();
                  }
                  handleExpantion(addToExpanded, idx);
              } }
            >
                { title }

                { !noNextLevel
                    && expandableIcons(checkExpantion(expandedList, idx)) }
            </a>
            { checkExpantion(expandedList, idx) && (
                <ul className="menu-item-second-level">
                    { secondLevelItems.map((secondLevel, index) => (
                        <RenderSecondLevel
                          key={ secondLevel.item_id }
                          secondLevel={ secondLevel }
                          idx={ index }
                          toggleMobileMenu={ toggleMobileMenu }
                        />
                    )) }
                </ul>
            ) }
        </li>
    );
}

export function MobileMenu(props) {
    const { mobMenuToggleRef, toggleMobileMenu, menu } = props;
    const categoryArray = Object.values(menu);

    if (!categoryArray.length) {
        return null;
    }

    const [{ children }] = categoryArray;
    const childrenArray = getSortedItems(Object.values(children));
    return (
        <header className="header">
            <nav className="navbar">
                <div className="menu-wrapper" ref={ mobMenuToggleRef }>
                    <ul className="menu">
                        { isSignedIn() ? (
                            <div className="login-link">
                                <Link
                                  to="my-account/dashboard"
                                  onClick={ toggleMobileMenu }
                                >
                                    My Account
                                </Link>
                            </div>
                        ) : (
                            <div className="login-link">
                                <Link
                                  to="account/login"
                                  onClick={ toggleMobileMenu }
                                >
                                    Login / Signup
                                </Link>
                            </div>
                        ) }

                        <div className="menu-block">
                            <span
                              className="close-menu"
                              onClick={ toggleMobileMenu }
                            >
                                <svg
                                  className="close-menu-button"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </span>
                        </div>

                        <div className="level-menu-wrapper">
                            { childrenArray.map((firstLevel, index) => (
                                <RenderFirstLevel
                                  key={ firstLevel.item_id }
                                  firstLevel={ firstLevel }
                                  idx={ index }
                                  toggleMobileMenu={ toggleMobileMenu }
                                />
                            )) }
                        </div>
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default MobileMenu;
