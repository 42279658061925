import PropTypes from 'prop-types';
import { lazy, Suspense } from 'react';

// import HomeSlider from 'Component/SliderWidget';
// import RenderWhenVisible from "SourceComponent/RenderWhenVisible";
import { WidgetFactory as SourceWidgetFactory } from 'SourceComponent/WidgetFactory/WidgetFactory.component';

import {
    BEST_SELLER,
    BLOG,
    BRAND_SLIDER,
    CATALOG_PRODUCT_LIST,
    NEW_PRODUCTS,
    POPULAR_CATEGORY,
    RECENTLY_VIEWED,
    SERVICES,
    SLIDER,
    STATIC_BLOCKS
} from './WidgetFactory.config';

import './WidgetFactory.style';

export const HomeSlider = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "cms" */ 'Component/SliderWidget'
));

export const ProductListWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/ProductListWidget'
));
export const NewProducts = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/NewProducts'
));
export const RecentlyViewedWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/RecentlyViewedWidget'
));

export const Services = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/Services'
));
export const PopularCategory = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/PopularCategory'
));

export const Blog = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/Blog'
));
export const BrandSlider = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/BrandSlider'
));

export const StaticBlocks = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/StaticBlocks'
));

export const BestSeller = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/BestSeller'
));

// import BestSeller from "Component/BestSeller";

/** @namespace Mspwa/Component/WidgetFactory/Component/WidgetFactoryComponent */
export class WidgetFactoryComponent extends SourceWidgetFactory {
    static propTypes = {
        type: PropTypes.string.isRequired
    };

    renderMap = {
        [SLIDER]: {
            component: HomeSlider,
            fallback: this.renderSliderFallback
        },
        [NEW_PRODUCTS]: {
            component: NewProducts
        },
        [CATALOG_PRODUCT_LIST]: {
            component: ProductListWidget
        },
        [RECENTLY_VIEWED]: {
            component: RecentlyViewedWidget
        },
        [BEST_SELLER]: {
            component: BestSeller
        },
        [SERVICES]: {
            component: Services
        },
        [POPULAR_CATEGORY]: {
            component: PopularCategory
        },
        [BRAND_SLIDER]: {
            component: BrandSlider
        },
        [STATIC_BLOCKS]: {
            component: StaticBlocks
        },
        [BLOG]: {
            component: Blog
        }
    };

    renderSliderFallback() {
        return <div block="WidgetFactory" elem="SliderPlaceholder" />;
    }

    renderDefaultFallback() {
        return <div />;
    }

    renderContent() {
        const { type } = this.props;
        // eslint-disable-next-line no-unused-vars
        const { component: Widget, fallback } = this.renderMap[type] || {};
        if (Widget !== undefined) {
            return (
                // <RenderWhenVisible fallback={ fallback }>
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                <Widget { ...this.props } />
                // </RenderWhenVisible>
            );
        }

        return null;
    }

    renderFallback() {
        const { type } = this.props;
        const { fallback = this.renderDefaultFallback } = this.renderMap[type] || {};

        return fallback();
    }

    render() {
        return (
            <Suspense fallback={ this.renderFallback() }>
                { this.renderContent() }
            </Suspense>
        );
    }
}

export default WidgetFactoryComponent;
