/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/prop-types */
import { useState } from 'react';

import CategoryNavLeft from './CategoryNavLeft';
import CategoryNavRight from './CategoryNavRight';

export function CategoryNav({ categories, closeMenu }) {
    const [currentCategory, setCurrentCategory] = useState(null);
    return (
        <div onMouseLeave={ () => setCurrentCategory(null) }>
            <CategoryNavLeft
              subItems={ categories }
              setCurrentCategory={ setCurrentCategory }
            />
            { currentCategory !== null && (
                <CategoryNavRight
                  category={ categories[currentCategory] }
                  closeMenu={ closeMenu }
                />
            ) }
        </div>
    );
}

export default CategoryNav;
