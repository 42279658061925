/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import facebookIcon from './images/fb.svg';
import instaIcon from './images/insta.svg';
// import linkedinIcon from './images/linkedin.png';
import pinterestIcon from './images/pinterest.svg';
import twitterIcon from './images/twitter.svg';
import youtubeIcon from './images/youtube.svg';

export const RENDER_NEWSLETTER = 'render_newsletter';

export const NEWSLETTER_COLUMN = {
  title: __('Newsletter'),
  columnActiveKey: 'newsletterActive',
  items: [
    {
      render: RENDER_NEWSLETTER,
    },
  ],
};

// eslint-disable-next-line import/prefer-default-export
export const COLUMN_MAP = [
  {
    title: 'Customer Support',
    items: [
      {
        href: '/faq',
        title: "FAQ's",
      },
      {
        href: '/cash-on-delivery',
        title: 'Cash on Delivery',
      },
      {
        href: '/refund-policy',
        title: 'Refund Policy',
      },
      {
        href: '/warranties',
        title: 'Warranties',
      },
      {
        href: '/terms-of-service',
        title: 'Terms and Services',
      },
    ],
  },
  {
    title: 'Information',
    items: [
      {
        href: 'https://blog.medisouq.com/',
        title: 'Health Blog',
      },
      {
        href: '/associates',
        title: 'Associates',
      },
      {
        href: '/contact',
        title: 'Contact',
      },
      {
        href: '/site-map',
        title: 'SiteMap',
      },
      {
        href: '/affiliates-program',
        title: 'Affiliate',
      },
    ],
  },
  {
    title: 'My Account',
    items: [
      {
        href: '/my-account/dashboard',
        title: 'Your Account',
      },
      {
        href: '/checkout',
        title: 'Checkout',
      },
      {
        href: '/login',
        title: 'Login',
      },
      {
        href: '/account/create',
        title: 'Register',
      },
      {
        href: '/privilege-card/registration',
        title: 'Privilege Card',
      },
    ],
  },
  {
    title: 'Who We Are',
    items: [
      {
        href: '/about-us',
        title: 'About Us',
      },
      {
        href: '/career',
        title: 'Career',
      },
      {
        href: '/privacy-policy',
        title: 'Privacy Policy',
      },
      {
        href: 'https://www.pharmsell.com/',
        title: 'Sell with us',
      },
      {
        href: 'https://www.pharmsell.com/',
        title: 'Join Your Pharmacy',
      },
    ],
  },
  {
    title: 'Contact us',
    lastColumn: true,
    items: [
      {
        label: 'Phone',
        value: '+971 501913913',
      },
      {
        label: 'Email',
        value: 'info@medisouq.com',
      },
      {
        content:
          'MEDISOUQ BIOPHARMA FZE Dafza,Office 7WA G040 Dubai UAE PB : 293648',
      },
      {
        social: true,
        items: [
          {
            href: 'https://www.facebook.com/medisouq',
            src: facebookIcon,
            title: 'Facebook',
          },
          {
            href: 'https://twitter.com/medisouqcom',
            src: twitterIcon,
            title: 'Twitter',
          },
          {
            href: 'https://www.pinterest.com/medisouqcom',
            src: pinterestIcon,
            title: 'Twitter',
          },
          {
            href: 'https://www.youtube.com/channel/UCaYYgsc7e9pN0-nSzSFhF2Q',
            src: youtubeIcon,
            title: 'Twitter',
          },
          {
            href: 'https://www.instagram.com/medisouqcom/',
            src: instaIcon,
            title: 'Instagram',
          },
        ],
      },
    ],
  },
];
