/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/prop-types */
import Link from 'Component/Link';

// import history from 'Util/History';
import './CategoryNavLeft.scss';

export default function CategoryNavLeft({ subItems, setCurrentCategory }) {
    return (
        <div className="CategoryLeftMain">
            <div className="CategoryLeftMain-NavBarMain">
                { subItems.map((item, idx) => (
                    <Link
                      block="CategoryLeftMain"
                      elem="NavBarMain-Titles"
                      onMouseOver={ () => {
                          setCurrentCategory(idx);
                      } }
                      to={ item.url.pathname }
                      key={ idx }
                    >
                        <span
                          block="CategoryLeftMain"
                          elem="NavBarMain-Titles-Name"
                        >
                            { item.title }
                        </span>

                        <svg
                          className="CategoryLeftMain-NavBarMain-Titles-Svg"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M9 5l7 7-7 7"
                            />
                        </svg>
                    </Link>
                )) }
            </div>
        </div>
    );
}
