/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
// import Image from 'Component/Image';
import Link from 'Component/Link';
import NewsletterSubscription from 'Component/NewsletterSubscription';
import { DeviceType } from 'Type/Device';

// import media from 'Util/Media';
import {
  COLUMN_MAP,
  NEWSLETTER_COLUMN,
  RENDER_NEWSLETTER,
} from './Footer.config';
import codImage from './images/cod.png';
import masterImage from './images/master-card.png';
import visaImage from './images/visa.png';

import './Footer.style';

/**
 * Page footer
 * @class Footer
 * @namespace Mspwa/Component/Footer/Component/FooterComponent */
export class FooterComponent extends PureComponent {
  static propTypes = {
    copyright: PropTypes.string,
    isVisibleOnMobile: PropTypes.bool,
    device: DeviceType.isRequired,
    // eslint-disable-next-line react/boolean-prop-naming
    newsletterActive: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    copyright: '',
    isVisibleOnMobile: false,
  };

  renderMap = {
    [RENDER_NEWSLETTER]: {
      render: this.renderNewsletterSubscriptionBlock.bind(this),
    },
  };

  renderColumnItemContent(src, title) {
    if (!src) {
      return title;
    }

    // const re = /^data:/i;
    // const imgSrc = re.test(src) ? src : media(src, '', false);

    return <img mix={{ block: 'Footer', elem: 'ColumnItemImage' }} src={src} />;
  }

  renderColumnItemLink = ({ href = '/', title, src }, i) => {
    const mods = src ? { type: 'image' } : {};

    return (
      <Link
        block='Footer'
        elem='ColumnItem'
        to={href}
        mods={mods}
        key={i}
        aria-label={title}>
        {this.renderColumnItemContent(src, title)}
      </Link>
    );
  };

  renderColumnItem = (item, i) => {
    const { render } = item;

    if (render) {
      return this.renderMap[render].render(item, i);
    }

    return this.renderColumnItemLink(item, i);
  };

  renderSocialLinks = (soc, i) => (
    <Link
      className='SocialIcon'
      to={soc.href}
      // mods={mods}
      key={i}
      aria-label={soc.title}>
      {this.renderColumnItemContent(soc.src, soc.title)}
    </Link>
  );

  renderLastColumn = (item, i) => {
    if (item.content) {
      return (
        <div key={i} className='ColContent'>
          {item.content}
        </div>
      );
    }
    if (item.social) {
      return (
        <div key={i} className='SocialLinks'>
          {item.items.map((soc, i) => this.renderSocialLinks(soc, i))}
        </div>
      );
    }

    return (
      <div key={i} className='Contacts'>
        <div className='LastColumnLabel'>
          {item.label}
          {': '}
        </div>
        <div className='LastColumnValue'>{item.value}</div>
      </div>
    );
  };

  renderColumn = (column, i) => {
    const {
      title,
      columnActiveKey,
      items,
      isItemsHorizontal,
      lastColumn,
      mods = {},
    } = column;

    const contentMods = isItemsHorizontal ? { direction: 'horizontal' } : {};

    const { [columnActiveKey]: isColumnActive } = this.props;

    if (columnActiveKey && !isColumnActive === true) {
      return null;
    }

    if (lastColumn) {
      return (
        <div block='Footer' elem='Column' mods={mods} key={i}>
          <h3 block='Footer' elem='ColumnTitle'>
            {title}
          </h3>
          <div block='Footer' elem='ColumnContent' mods={contentMods}>
            {items.map(this.renderLastColumn)}
          </div>
        </div>
      );
    }

    return (
      <div block='Footer' elem='Column' mods={mods} key={i}>
        <h3 block='Footer' elem='ColumnTitle'>
          {title}
        </h3>
        <div block='Footer' elem='ColumnContent' mods={contentMods}>
          {items.map(this.renderColumnItem)}
        </div>
      </div>
    );
  };

  renderColumns() {
    return (
      <ContentWrapper
        isNotSection
        wrapperMix={{ block: 'Footer', elem: 'Columns' }}
        label=''>
        {COLUMN_MAP.map(this.renderColumn)}
      </ContentWrapper>
    );
  }

  renderNewsletterSubscriptionBlock(item, i) {
    return <NewsletterSubscription key={i} />;
  }

  renderCmsBlockWrapper() {
    const { footer_content: { footer_cms } = {} } = window.contentConfiguration;

    return (
      <div
        block='Footer'
        elem='CmsBlockWrapper'
        mix={{ block: 'Footer', elem: 'Content' }}>
        <div block='Footer' elem='Columns' mix={{ block: 'ContentWrapper' }}>
          <CmsBlock identifier={footer_cms} />
          {this.renderColumn({
            ...NEWSLETTER_COLUMN,
            mods: { isNewsletter: true },
          })}
        </div>
      </div>
    );
  }

  renderContent() {
    const { footer_content: { footer_cms } = {} } = window.contentConfiguration;

    if (footer_cms) {
      return this.renderCmsBlockWrapper();
    }

    return (
      <div block='Footer' elem='Content'>
        {this.renderColumns()}
        <div className='DownContent'>
          Product names, logos, brands and other trademarks referred to within
          Medisouq.com’s listed products and services are the property of their
          respective trademark holders.These trademark holders are not
          affiliated with Medisouq.com products, or our website.
        </div>
      </div>
    );
  }

  renderCopyrightContent() {
    // const { copyright } = this.props;
    const YEAR = new Date().getFullYear();

    return (
      <div>
        <div className='FooterDown'>
          <div className='FooterDown-FooterLeft'>
            Medisouq @{YEAR} #1 Health & Wellness Store
          </div>

          <div className='FooterDown-FooterMiddle'>
            MOH license number : DJ09220-28/04/2016
          </div>
          <div className='FooterDown-TechProvided'>
            Powered by{' '}
            <a className='FooterDown-HodLink' href='/#'>
              Biopharma
            </a>
          </div>
          <div className='FooterDown-FooterRight'>
            <div className='FooterDown-FooterRight-Img'>
              {this.renderColumnItemContent(visaImage, 'Visa')}
            </div>
            <div className='FooterDown-FooterRight-Img'>
              {this.renderColumnItemContent(codImage, 'Cod')}
            </div>
            <div className='FooterDown-FooterRight-Img'>
              {this.renderColumnItemContent(masterImage, 'Master')}
            </div>

            {/* {this.renderColumnItemContent(soc.src, soc.title)} */}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { isVisibleOnMobile, device } = this.props;

    if (!isVisibleOnMobile && device.isMobile) {
      return null;
    }

    if (isVisibleOnMobile && !device.isMobile) {
      return null;
    }

    return (
      <footer block='Footer' aria-label='Footer'>
        {this.renderContent()}
        {this.renderCopyrightContent()}
      </footer>
    );
  }
}

export default FooterComponent;
