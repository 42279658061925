/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';

import Link from 'Component/Link';
import history from 'Util/History';

import './MegaMenu.scss';

export default function MegaManu({ categories, closeMenu }) {
    const subCategories = categories.filter((cate) => !cate.icon);
    const banners = categories.filter((cate) => cate.icon);
    const bannerTop = banners[0] || {};
    const bannerLeft = banners[1] || {};
    const bannerRight = banners[2] || {};

    return (
        <div className="Body">
            <div className="Body-MegaMenu">
                { subCategories.map((sC) => {
                    const { children = {} } = sC;
                    const subLinks = Object.values(children);
                    return (
                        <div key={ sC.item_id } className="Body-MegaMenu-Menu">
                            <Link
                              onClick={ () => {
                                  //   window.location.href = sC.url.pathname;
                                  //   history.push(sC.url.pathname);
                                  closeMenu();
                              } }
                              to={ sC.url.pathname }
                              block="Body"
                              elem="MegaMenu-Menu-HeaderName"
                            >
                                { sC.title }
                            </Link>
                            <div className="Body-MegaMenu-Menu-LinkContainer">
                                { subLinks.map((sL, i) => (
                                    <Link
                                      key={ i }
                                      block="Body"
                                      elem="MegaMenu-Menu-LinkContainer-Link"
                                      onClick={ () => {
                                          //   window.location.href = sL.url.pathname;
                                          //   history.push(sL.url.pathname);
                                          closeMenu();
                                      } }
                                      to={ sL.url.pathname }
                                    >
                                        { sL.title }
                                    </Link>
                                )) }
                            </div>
                        </div>
                    );
                }) }
                { banners.length > 0 && (
                    <div className="Body-MegaMenu-MainBanner">
                        <div className="Body-MegaMenu-MainBanner-MainBannerContainer">
                            <div
                              onClick={ () => {
                                  history.push(bannerTop.url);
                                  closeMenu();
                              } }
                              className="Body-MegaMenu-MainBanner-MainBannerContainer-Main"
                            >
                                <img
                                  className="Body-MegaMenu-MainBanner-MainBannerContainer-Main-Img"
                                  src={ bannerTop.icon }
                                  alt="Snow"
                                />
                                { /* <div className="Body-MegaMenu-MainBanner-MainBannerContainer-Main-Titles Titles">
                                    <h1 className="Body-MegaMenu-MainBanner-MainBannerContainer-Main-Titles-Name">
                                        { bannerTop.title }
                                    </h1>
                                    <button className="Body-MegaMenu-MainBanner-MainBannerContainer-Main-Titles-Button">
                                        BUY NOW
                                    </button>
                                </div> */ }
                            </div>
                            <div
                              onClick={ () => {
                                  history.push(bannerLeft.url);
                                  closeMenu();
                              } }
                              className="Body-MegaMenu-MainBanner-MainBannerContainer-Main"
                            >
                                <img
                                  className="Body-MegaMenu-MainBanner-MainBannerContainer-Main-Img"
                                  src={ bannerLeft.icon }
                                  alt="Snow"
                                />
                                { /* <div className="Body-MegaMenu-MainBanner-MainBannerContainer-Main-Titles Titles">
                                    <h1 className="Body-MegaMenu-MainBanner-MainBannerContainer-Main-Titles-Name">
                                        { bannerLeft.title }
                                    </h1>
                                    <button className="Body-MegaMenu-MainBanner-MainBannerContainer-Main-Titles-Button">
                                        BUY NOW
                                    </button>
                                </div> */ }
                            </div>
                            <div
                              onClick={ () => {
                                  history.push(bannerRight.url);
                                  closeMenu();
                              } }
                              className="Body-MegaMenu-MainBanner-MainBannerContainer-Main"
                            >
                                <img
                                  className="Body-MegaMenu-MainBanner-MainBannerContainer-Main-Img"
                                  src={ bannerRight.icon }
                                  alt="Snow"
                                />
                                { /* <div className="Body-MegaMenu-MainBanner-MainBannerContainer-Main-Titles Titles">
                                    <h1 className="Body-MegaMenu-MainBanner-MainBannerContainer-Main-Titles-Name">
                                        { bannerRight.title }
                                    </h1>
                                    <button className="Body-MegaMenu-MainBanner-MainBannerContainer-Main-Titles-Button">
                                        NUY NOW
                                    </button>
                                </div> */ }
                            </div>
                        </div>
                    </div>
                ) }
            </div>
        </div>
    );
}
