/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable max-lines */
/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { createRef, lazy, Suspense } from 'react';

import MobileMenu from 'Component/MobileMenu';
import ClickOutside from 'SourceComponent/ClickOutside';
// import CmsBlock from 'SourceComponent/CmsBlock';
import CurrencySwitcher from 'SourceComponent/CurrencySwitcher';
import { Header as SourceHeader } from 'SourceComponent/Header/Header.component';
import Link from 'SourceComponent/Link';
import Logo from 'SourceComponent/Logo';
import Menu from 'SourceComponent/Menu';
import { CUSTOMER_ACCOUNT_OVERLAY_KEY } from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.config';
// import NavigationAbstract from 'SourceComponent/NavigationAbstract/NavigationAbstract.component';
import { DEFAULT_STATE_NAME } from 'SourceComponent/NavigationAbstract/NavigationAbstract.config';
import OfflineNotice from 'SourceComponent/OfflineNotice';
import PopupSuspense from 'SourceComponent/PopupSuspense';
import SearchField from 'SourceComponent/SearchField';
import StoreSwitcher from 'SourceComponent/StoreSwitcher';
import { DeviceType } from 'Type/Device';
import { TotalsType } from 'Type/MiniCart';
import { isSignedIn } from 'Util/Auth';
import CSS from 'Util/CSS';
import history from 'Util/History';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';

import {
    CART,
    CART_EDITING,
    CART_OVERLAY,
    CATEGORY,
    CHECKOUT,
    CHECKOUT_ACCOUNT,
    CHECKOUT_SUCCESS,
    CMS_PAGE,
    CONTACT_US,
    CUSTOMER_ACCOUNT,
    CUSTOMER_ACCOUNT_PAGE,
    CUSTOMER_SUB_ACCOUNT,
    CUSTOMER_WISHLIST,
    FILTER,
    MENU,
    MENU_SUBCATEGORY,
    PDP,
    POPUP,
    PRODUCT_COMPARE,
    SEARCH
} from './Header.config';
import logo from './Image/medisouq-logo.svg';

import './Header.style';

export const CartOverlay = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "cart" */ 'SourceComponent/CartOverlay'
));
export const MyAccountOverlay = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account" */ 'SourceComponent/MyAccountOverlay'
));

/** @namespace Mspwa/Component/Header/Component/HeaderComponent */
export class HeaderComponent extends SourceHeader {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        navigationState: PropTypes.object.isRequired,
        cartTotals: TotalsType.isRequired,
        onBackButtonClick: PropTypes.func.isRequired,
        onCloseButtonClick: PropTypes.func.isRequired,
        onSearchBarFocus: PropTypes.func.isRequired,
        onClearSearchButtonClick: PropTypes.func.isRequired,
        onMyAccountButtonClick: PropTypes.func.isRequired,
        onSearchBarChange: PropTypes.func.isRequired,
        onClearButtonClick: PropTypes.func.isRequired,
        isWishlistLoading: PropTypes.bool.isRequired,
        onEditButtonClick: PropTypes.func.isRequired,
        onMinicartButtonClick: PropTypes.func.isRequired,
        onOkButtonClick: PropTypes.func.isRequired,
        onCancelButtonClick: PropTypes.func.isRequired,
        onSearchOutsideClick: PropTypes.func.isRequired,
        onMyAccountOutsideClick: PropTypes.func.isRequired,
        onMinicartOutsideClick: PropTypes.func.isRequired,
        isClearEnabled: PropTypes.bool.isRequired,
        searchCriteria: PropTypes.string.isRequired,
        shareWishlist: PropTypes.func.isRequired,
        header_logo_src: PropTypes.string,
        logo_alt: PropTypes.string,
        logo_height: PropTypes.number,
        logo_width: PropTypes.number,
        isLoading: PropTypes.bool,
        showMyAccountLogin: PropTypes.bool,
        isCheckout: PropTypes.bool.isRequired,
        onSignIn: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        device: DeviceType.isRequired
    };

    static defaultProps = {
        logo_alt: 'ScandiPWA logo',
        logo_height: 25,
        logo_width: 200,
        showMyAccountLogin: false,
        header_logo_src: '',
        isLoading: true
    };

    logoRef = createRef();

    mobMenuToggleRef = createRef();

    toggleMobileMenu() {
        const menuRef = this.mobMenuToggleRef.current;
        if (menuRef.classList.contains('offcanvas')) {
            menuRef.classList.remove('offcanvas');
        } else {
            menuRef.classList.add('offcanvas');
        }
    }

    stateMap = {
        [DEFAULT_STATE_NAME]: {
            title: true,
            logo: true
        },
        [POPUP]: {
            title: true,
            close: true
        },
        [PDP]: {
            back: true,
            title: true
        },
        [CATEGORY]: {
            back: true,
            title: true
        },
        [CUSTOMER_ACCOUNT]: {
            title: true
        },
        [CUSTOMER_SUB_ACCOUNT]: {
            title: true,
            back: true
        },
        [CUSTOMER_ACCOUNT_PAGE]: {
            title: true
        },
        [CUSTOMER_WISHLIST]: {
            share: true,
            title: true,
            edit: true,
            ok: true
        },
        [MENU]: {
            search: true
        },
        [MENU_SUBCATEGORY]: {
            back: true,
            title: true
        },
        [SEARCH]: {
            back: true,
            search: true
        },
        [CART]: {
            title: true
        },
        [CART_OVERLAY]: {
            title: true
        },
        [CART_EDITING]: {
            ok: true,
            title: true,
            cancel: true
        },
        [FILTER]: {
            close: true,
            clear: true,
            title: true
        },
        [CHECKOUT]: {
            back: true,
            title: true,
            account: true
        },
        [CHECKOUT_SUCCESS]: {
            title: true,
            account: true
        },
        [CHECKOUT_ACCOUNT]: {
            title: true,
            close: true
        },
        [CMS_PAGE]: {
            back: true,
            title: true
        },
        [CONTACT_US]: {
            title: true,
            back: true
        },
        [PRODUCT_COMPARE]: {
            title: true,
            back: true
        }
    };

    // state = {
    //     mobileMenu: false
    // };

    // toggleMobileMenu(){

    // }

    renderMap = {
        cancel: this.renderCancelButton.bind(this),
        back: this.renderBackButton.bind(this),
        close: this.renderCloseButton.bind(this),
        share: this.renderShareWishListButton.bind(this),
        title: this.renderTitle.bind(this),
        logo: this.renderLogo.bind(this),
        // account: this.renderAccount.bind(this),
        // minicart: this.renderMinicart.bind(this),
        // compare: this.renderComparePageButton.bind(this),
        // search: this.renderSearchField.bind(this),
        clear: this.renderClearButton.bind(this),
        edit: this.renderEditButton.bind(this),
        ok: this.renderOkButton.bind(this)
    };

    renderBackButton(isVisible = false) {
        const { onBackButtonClick } = this.props;

        return (
            <button
              key="back"
              block="Header"
              elem="Button"
              mods={ { type: 'back', isVisible } }
              onClick={ onBackButtonClick }
              aria-label="Go back"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
            />
        );
    }

    renderCloseButton(isVisible = false) {
        const { onCloseButtonClick } = this.props;

        return (
            <button
              key="close"
              block="Header"
              elem="Button"
              mods={ { type: 'close', isVisible } }
              onClick={ onCloseButtonClick }
              aria-label="Close"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
            />
        );
    }

    renderMenu() {
        const { isCheckout } = this.props;

        // if (device.isMobile || isCheckout) {
        //     return null;
        // }

        return <Menu isCheckout={ isCheckout } />;
    }

    renderSearchField(isVisible = false) {
        const {
            searchCriteria,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            navigationState: { name },
            hideActiveOverlay,
            klevuSearchResult,
            isKlevuSearch,
            klevuLoading
        } = this.props;

        return (
            <SearchField
              key="search"
              searchCriteria={ searchCriteria }
              onSearchOutsideClick={ onSearchOutsideClick }
              onSearchBarFocus={ onSearchBarFocus }
              onSearchBarChange={ onSearchBarChange }
              onClearSearchButtonClick={ onClearSearchButtonClick }
              isVisible={ isVisible }
              isActive={ name === SEARCH }
              hideActiveOverlay={ hideActiveOverlay }
              isKlevuSearch={ isKlevuSearch }
              klevuSearchResult={ klevuSearchResult }
              klevuLoading={ klevuLoading }
            />
        );
    }

    renderShareWishListButton(isVisible = false) {
        const { isWishlistLoading, shareWishlist } = this.props;

        return (
            <button
              key="share"
              block="Header"
              elem="Button"
              mods={ { type: 'share', isVisible } }
              onClick={ shareWishlist }
              aria-label="Share"
              aria-hidden={ !isVisible }
              disabled={ isWishlistLoading }
            />
        );
    }

    renderComparePageButton() {
        const { device: { isMobile } = {}, isCheckout } = this.props;

        if (isCheckout || isMobile) {
            return null;
        }

        return (
            <div block="Header" elem="CompareButtonWrapper" key="compare">
                <Link
                  to="compare"
                  key="compare"
                  block="Header"
                  elem="Button"
                  mods={ { type: 'compare' } }
                  aria-label={ __('Compare Page') }
                >
                    <div block="Header" elem="CompareIcon" />
                </Link>
            </div>
        );
    }

    renderTitle(isVisible = false) {
        const {
            navigationState: { title }
        } = this.props;

        return (
            <h1 key="title" block="Header" elem="Title" mods={ { isVisible } }>
                { title }
            </h1>
        );
    }

    renderLogoImage() {
        const {
            header_logo_src, logo_alt, logo_height, logo_width
        } = this.props;

        // if no src defined from the backend, pass null in order to display placeholder
        // and prevent unnecessary load of corrupted resource
        const logoSrc = header_logo_src
            ? media(header_logo_src, LOGO_MEDIA)
            : null;

        CSS.setVariable(this.logoRef, 'header-logo-height', `${logo_height}px`);
        CSS.setVariable(this.logoRef, 'header-logo-width', `${logo_width}px`);

        return <Logo src={ logoSrc } alt={ logo_alt } title={ logo_alt } />;
    }

    renderLogo(isVisible = false) {
        const { isLoading } = this.props;

        if (isLoading) {
            return null;
        }

        return (
            <Link
              to="/"
              aria-label="Go to homepage by clicking on ScandiPWA logo"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
              block="Header"
              elem="LogoWrapper"
              mods={ { isVisible } }
              key="logo"
            >
                { this.renderLogoImage() }
            </Link>
        );
    }

    renderAccountOverlayFallback() {
        return (
            <PopupSuspense actualOverlayKey={ CUSTOMER_ACCOUNT_OVERLAY_KEY } />
        );
    }

    renderAccountOverlay() {
        const { isCheckout, showMyAccountLogin, onSignIn } = this.props;

        // This is here to prevent the popup-suspense from rendering
        if (!showMyAccountLogin) {
            return null;
        }

        return (
            <Suspense fallback={ this.renderAccountOverlayFallback() }>
                <MyAccountOverlay onSignIn={ onSignIn } isCheckout={ isCheckout } />
            </Suspense>
        );
    }

    renderAccountButton() {
        const { onMyAccountButtonClick } = this.props;

        return (
            <button
              block="Header"
              elem="MyAccountWrapper"
              tabIndex="0"
              onClick={ onMyAccountButtonClick }
              aria-label="Open my account"
              id="myAccount"
            >
                <div block="Header" elem="MyAccountTitle">
                    { isSignedIn() ? 'Account' : 'Login / Signup' }
                </div>
                { /* <div
          block="Header"
          elem="Button"
          mods={ { isVisible, type: 'account' } }
        /> */ }
            </button>
        );
    }

    renderAccount(isVisible = false) {
        const { onMyAccountOutsideClick, isCheckout, device } = this.props;

        // on mobile hide button if not in checkout
        if (device.isMobile && !isCheckout) {
            return null;
        }

        if (isCheckout && isSignedIn()) {
            return null;
        }

        return (
            <ClickOutside onClick={ onMyAccountOutsideClick } key="account">
                <div aria-label="My account" block="Header" elem="MyAccount">
                    { this.renderAccountOverlay() }
                    { this.renderAccountButton(isVisible) }
                </div>
            </ClickOutside>
        );
    }

    renderMinicartItemsQty() {
        const {
            cartTotals: { items_qty }
        } = this.props;

        if (!items_qty) {
            return null;
        }

        return (
            <span
              aria-label="Items in cart"
              block="Header"
              elem="MinicartItemCount"
            >
                { items_qty }
            </span>
        );
    }

    renderMinicartOverlayFallback() {
        return <PopupSuspense actualOverlayKey={ CART_OVERLAY } />;
    }

    renderMinicartOverlay() {
        const { shouldRenderCartOverlay } = this.props;

        if (!shouldRenderCartOverlay) {
            return null;
        }

        return (
            <Suspense fallback={ this.renderMinicartOverlayFallback() }>
                <CartOverlay />
            </Suspense>
        );
    }

    renderMinicartButton() {
        const { onMinicartButtonClick } = this.props;

        return (
            // <button
            //   block="Header"
            //   elem="MinicartButtonWrapper"
            //   tabIndex="0"
            //   onClick={ onMinicartButtonClick }
            // >
            //   <span block="Header" elem="MinicartTitle">
            //     { __('Cart') }
            //   </span>
            //   <span aria-label="Minicart" block="Header" elem="MinicartIcon" />
            //   { this.renderMinicartItemsQty() }
            // </button>
            <button className="Cart" onClick={ onMinicartButtonClick }>
                <svg
                  className="CartSvg"
                  width="18"
                  height="17.938"
                  viewBox="0 0 18 17.938"
                >
                    <path
                      id="Icon"
                      className="cls-1"
                      d="M1476.38,113.391a1.8,1.8,0,1,0,1.8,1.8A1.8,1.8,0,0,0,1476.38,113.391Zm-5.4-14.375v1.8h1.8l3.24,6.828-1.26,2.156a3.184,3.184,0,0,0-.18.9,1.8,1.8,0,0,0,1.8,1.8h10.8v-1.8h-10.44a0.194,0.194,0,0,1-.18-0.18v-0.089l0.81-1.528h6.66a1.642,1.642,0,0,0,1.53-.9l3.24-5.84a0.494,0.494,0,0,0,.18-0.449,0.849,0.849,0,0,0-.9-0.9h-13.32l-0.81-1.8h-2.97Zm14.4,14.375a1.8,1.8,0,1,0,1.8,1.8A1.8,1.8,0,0,0,1485.38,113.391Z"
                      transform="translate(-1470.97 -99.031)"
                    />
                </svg>
                <div className="Qty">{ this.renderMinicartItemsQty() }</div>
            </button>
        );
    }

    renderMinicart(_isVisible = false) {
        const { onMinicartOutsideClick } = this.props;

        return (
            <ClickOutside onClick={ onMinicartOutsideClick } key="minicart">
                <div>
                    { this.renderMinicartButton() }
                    { this.renderMinicartOverlay() }
                </div>
            </ClickOutside>
        );
    }

    renderClearButton(isVisible = false) {
        const { isClearEnabled, onClearButtonClick } = this.props;

        return (
            <button
              key="clear"
              block="Header"
              elem="Button"
              mods={ { type: 'clear', isVisible, isDisabled: !isClearEnabled } }
              onClick={ onClearButtonClick }
              aria-label="Clear"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
            />
        );
    }

    renderEditButton(isVisible = false) {
        const { onEditButtonClick } = this.props;

        return (
            <button
              key="edit"
              block="Header"
              elem="Button"
              mods={ { type: 'edit', isVisible } }
              onClick={ onEditButtonClick }
              aria-label="Edit"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
            >
                { __('Edit') }
            </button>
        );
    }

    renderOkButton(isVisible = false) {
        const { onOkButtonClick } = this.props;

        return (
            <button
              key="ok"
              block="Header"
              elem="Button"
              mods={ { type: 'ok', isVisible } }
              onClick={ onOkButtonClick }
              aria-label="Save changes"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
            >
                { __('OK') }
            </button>
        );
    }

    renderCancelButton(isVisible = false) {
        const { onCancelButtonClick } = this.props;

        return (
            <button
              key="cancel"
              block="Header"
              elem="Button"
              mods={ { type: 'cancel', isVisible } }
              onClick={ onCancelButtonClick }
              aria-label="Cancel changes"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
            >
                { __('Cancel') }
            </button>
        );
    }

    renderMainHeader() {
        // following strings are not translated, use CMS blocks to do it
        return (
            <div block="Header-Contacts" elem="Top">
                <div className="hanburgerWrapper">
                    <svg fill="none" stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 6h16M4 10h16M4 14h16M4 18h16"
                        />
                    </svg>
                </div>
                <div className="LogoWrapperLogo">
                    <Link
                      to="/"
                      aria-label="Go to homepage by clicking on ScandiPWA logo"
                      key="logo"
                      className="logo"
                    >
                        <img className="logo" src={ logo } alt="" />
                    </Link>
                </div>
                <div className="SearchWrapper">{ this.renderSearchField() }</div>
                <div className="UploadWrapper">
                    <button block="UploadWrapper" elem="UploadIcon">
                        <svg
                          id="Isolation_Mode"
                          data-name="Isolation Mode"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 39.2 39.2"
                          fill="#90cc8d"
                        >
                            <defs />
                            <path
                              className="cls-1"
                              d="M21.52 12a1.5 1.5 0 0 0-1.1-.4 2.1 2.1 0 0 0-1.1.4l-5.9 5.8a1.45 1.45 0 0 0 0 2.1 1.23 1.23 0 0 0 1.9 0l3.1-3.3.1 11.6a1.5 1.5 0 0 0 3 0l-.1-11.6 3.5 3.4a1.75 1.75 0 0 0 1.2.4 1.5 1.5 0 0 0 1.1-.4 1.45 1.45 0 0 0 0-2.1Z"
                              transform="translate(-.72 -.96)"
                            />
                            <path
                              className="cls-1"
                              d="M20.32 1a19.6 19.6 0 1 0 19.6 19.6A19.63 19.63 0 0 0 20.32 1Zm0 36.2a16.6 16.6 0 1 1 16.6-16.6 16.56 16.56 0 0 1-16.6 16.56Z"
                              transform="translate(-.72 -.96)"
                            />
                        </svg>
                    </button>
                    <div
                      block="UploadWrapper"
                      elem="UploadLabel"
                      onClick={ () => {
                          history.push('/upload-prescription');
                      } }
                    >
                        Upload Prescription
                    </div>
                </div>
            </div>
        );
    }

    renderTopMenu() {
        // const { device } = this.props;
        // if (device.isMobile) {
        //     return null;
        // }

        return (
            <div block="Header" elem="TopMenu">
                <div block="Header" elem="Contacts">
                    { this.renderMainHeader() }
                </div>
                <div block="Header" elem="Switcher">
                    <CurrencySwitcher />
                    <StoreSwitcher />
                </div>
            </div>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    mobileHeader() {
        // this.toggleMobileMenu();
        return (
            <>
                <MobileMenu
                  mobMenuToggleRef={ this.mobMenuToggleRef }
                  toggleMobileMenu={ this.toggleMobileMenu.bind(this) }
                />
                <header className="MobileHeader">
                    <div className="Top">
                        <div
                          className="Hanburger"
                          onClick={ this.toggleMobileMenu.bind(this) }
                        >
                            <svg
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="TopSvg"
                            >
                                <line x1="3" y1="12" x2="21" y2="12" />
                                <line x1="3" y1="6" x2="21" y2="6" />
                                <line x1="3" y1="18" x2="21" y2="18" />
                            </svg>
                        </div>
                        <div className="Logo">
                            <Link
                              to="/"
                              aria-label="Go to homepage by clicking on ScandiPWA logo"
                              key="logo"
                              className="logo"
                            >
                                <img className="logo" src={ logo } alt="" />
                            </Link>
                        </div>

                        <a className="Cart" href="/cart">
                            <svg
                              className="CartSvg"
                              width="18"
                              height="17.938"
                              viewBox="0 0 18 17.938"
                            >
                                <path
                                  id="Icon"
                                  className="cls-1"
                                  d="M1476.38,113.391a1.8,1.8,0,1,0,1.8,1.8A1.8,1.8,0,0,0,1476.38,113.391Zm-5.4-14.375v1.8h1.8l3.24,6.828-1.26,2.156a3.184,3.184,0,0,0-.18.9,1.8,1.8,0,0,0,1.8,1.8h10.8v-1.8h-10.44a0.194,0.194,0,0,1-.18-0.18v-0.089l0.81-1.528h6.66a1.642,1.642,0,0,0,1.53-.9l3.24-5.84a0.494,0.494,0,0,0,.18-0.449,0.849,0.849,0,0,0-.9-0.9h-13.32l-0.81-1.8h-2.97Zm14.4,14.375a1.8,1.8,0,1,0,1.8,1.8A1.8,1.8,0,0,0,1485.38,113.391Z"
                                  transform="translate(-1470.97 -99.031)"
                                />
                            </svg>
                            <div className="Qty">
                                { this.renderMinicartItemsQty() }
                            </div>
                        </a>
                    </div>

                    <div className="Down">
                        <div className="Upload">
                            <button block="Upload" elem="UploadIcon">
                                <svg
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={ 2 }
                                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                                    />
                                </svg>
                            </button>
                            <div block="Upload" elem="UploadLabel">
                                Upload
                                <p>Prescription</p>
                            </div>
                        </div>
                        <div className="Search">
                            <div className="SearchWrapper">
                                { this.renderSearchField() }
                            </div>
                        </div>
                    </div>
                </header>
            </>
        );
    }

    render() {
        const { stateMap } = this;
        const {
            navigationState: { name },
            isCheckout,
            device
        } = this.props;

        if (!device.isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        if (device.isMobile) {
            return this.mobileHeader();
        }

        return (
            <section block="Header" elem="Wrapper">
                <header
                  block="Header"
                  mods={ { name, isCheckout } }
                  mix={ { block: 'FixedElement', elem: 'Top' } }
                  ref={ this.logoRef }
                >
                    { this.renderTopMenu() }
                    <nav block="Header" elem="Nav">
                        { /* {this.renderNavigationState()} */ }
                    </nav>
                    { !isCheckout ? (
                        <div className="LinksWrapper">
                            <div className="Links">
                                <div className="AccountWrapper">
                                    <div>{ this.renderAccount(true) }</div>
                                    <div>{ this.renderMinicart() }</div>
                                </div>
                                <div className="MenuWrapper">
                                    { this.renderMenu() }
                                </div>
                            </div>
                        </div>
                    ) : null }
                </header>
                <OfflineNotice />
            </section>
        );
    }
}

export default HeaderComponent;
