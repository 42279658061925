Mosaic.setPlugins([require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js'),
require('./../packages/gtm/src/plugin/AdditionalRoutes.plugin.js'),
require('./../packages/gtm/src/plugin/GTMHocWrapper.plugin.js'),
require('./../packages/gtm/src/plugin/StoreModifications.plugin.js'),
require('./../packages/gtm/src/plugin/TransactionId.plugin.js'),
require('./../packages/gtm/src/plugin/event-dispatches/Purchase.plugin.js'),
require('./../packages/gtm/src/plugin/event-dispatches/addToCart.plugin.js'),
require('./../packages/gtm/src/plugin/event-dispatches/cartItemChangeQuantity.plugin.js'),
require('./../packages/gtm/src/plugin/event-dispatches/cartItemRemove.plugin.js'),
require('./../packages/gtm/src/plugin/event-dispatches/checkout.plugin.js'),
require('./../packages/gtm/src/plugin/event-dispatches/checkoutOption.plugin.js'),
require('./../packages/gtm/src/plugin/event-dispatches/impressions.plugin.js'),
require('./../packages/gtm/src/plugin/event-dispatches/myAccountLoginRegister.plugin.js'),
require('./../packages/gtm/src/plugin/event-dispatches/productClick.plugin.js'),
require('./../packages/gtm/src/plugin/event-dispatches/productDetail.plugin.js')]);
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { render } from 'react-dom';

import App from 'Component/App';

import 'Util/Polyfill';
import 'Style/main';

// TODO: move this out to i18-runtime
PropTypes.string = PropTypes.oneOfType([PropTypes.string, PropTypes.object]);

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        navigator.serviceWorker.register(swUrl);
    });
}

render(<App />, document.getElementById('root'));
