export const UPDATE_SMART_CARD = 'UPDATE_SMART_CARD';

/**
 * Send message
 * @param {Object} data
 * @namespace Store/SmartCard/Action/updateSmartCard
 */
export const updateSmartCard = (data = {}) => ({
  type: UPDATE_SMART_CARD,
  data,
});
