import { getStaticReducers as baseGetStaticReducers } from 'SourceStore/index';

import SmartCardFormReducer from 'Store/SmartCard/SmartCardForm.reducer';

/** @namespace Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
  ...baseGetStaticReducers(),
  SmartCardFormReducer,
});

export default function injectStaticReducers(store) {
  Object.entries(getStaticReducers()).forEach(([name, reducer]) =>
    store.injectReducer(name, reducer)
  );

  return store;
}
