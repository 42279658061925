import { UPDATE_SMART_CARD } from './SmartCardForm.action';

export const initialState = {
  isLoading: false,
};

/** @namespace Store/SmartCard/Reducer/SmartCardFormReducer */
export const SmartCardFormReducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case UPDATE_SMART_CARD:
      return { ...state, ...data };

    default:
      return state;
  }
};

export default SmartCardFormReducer;
