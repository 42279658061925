/* eslint-disable react/boolean-prop-naming */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import {
    createRef, lazy, PureComponent, Suspense
} from 'react';

import ClickOutside from 'Component/ClickOutside';
import Loader from 'Component/Loader';
import { DeviceType } from 'Type/Device';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import './SearchField.style';

export const SearchOverlay = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "category" */
    'Component/SearchOverlay'
));

/** @namespace Mspwa/Component/SearchField/Component/SearchFieldComponent */
export class SearchFieldComponent extends PureComponent {
  static propTypes = {
      searchCriteria: PropTypes.string,
      onSearchBarFocus: PropTypes.func.isRequired,
      onSearchBarChange: PropTypes.func.isRequired,
      onSearchOutsideClick: PropTypes.func.isRequired,
      onClearSearchButtonClick: PropTypes.func.isRequired,
      isVisible: PropTypes.bool,
      isActive: PropTypes.bool,
      hideActiveOverlay: PropTypes.func,
      device: DeviceType.isRequired,
      klevuSearchResult: PropTypes.object,
      isKlevuSearch: PropTypes.bool,
      klevuLoading: PropTypes.bool
  };

  static defaultProps = {
      isVisible: true,
      isActive: true,
      searchCriteria: '',
      hideActiveOverlay: () => {}
  };

  searchBarRef = createRef();

  state = {
      isPlaceholderVisible: true,
      showSearch: false
  };

  static getDerivedStateFromProps(props) {
      const { isActive } = props;
      if (isActive) {
          return null;
      }

      return { isPlaceholderVisible: true };
  }

  componentDidUpdate() {
      const { showSearch } = this.state;

      if (showSearch) {
          this.onIconClick();
      }
  }

  onClearSearchButtonClick(isFocusOnSearchBar = true) {
      const { onClearSearchButtonClick } = this.props;
      if (isFocusOnSearchBar) {
          this.searchBarRef.current.focus();
      }
      onClearSearchButtonClick();
  }

  onSearchEnterPress = (e) => {
      const { searchCriteria, hideActiveOverlay, onSearchBarChange } = this.props;
      const search = searchCriteria.trim().replace(/\s\s+/g, '%20');
      const trimmedSearch = searchCriteria.trim();

      if (e.key === 'Enter' && trimmedSearch !== '') {
          history.push(appendWithStoreCode(`/search/${search}`));
          hideActiveOverlay();
          onSearchBarChange({ target: { value: '' } });
          this.searchBarRef?.current?.blur();
          this.closeSearch();
      }
  };

  onIconClick = () => {};

  openSearch = () => {
      const { onSearchBarFocus } = this.props;

      onSearchBarFocus();
      this.setState({ showSearch: true });
  };

  closeSearch = () => {
      const { onSearchOutsideClick } = this.props;

      onSearchOutsideClick();
      this.setState({ showSearch: false });
  };

  handleChange = (e) => {
      const {
          target: { value }
      } = e;
      const { onSearchBarChange } = this.props;
      onSearchBarChange(e);

      this.setState({ isPlaceholderVisible: value === '' });
  };

  clearSearch = () => {
      this.onClearSearchButtonClick(false);
  };

  renderClearSearch() {
      const { isVisible } = this.props;

      return (
      <button
        block="Header"
        elem="Button"
        onClick={ this.onClearSearchButtonClick }
        mods={ {
            type: 'searchClear',
            isVisible
        } }
        aria-label="Clear search"
      />
      );
  }

  renderOverlayFallback() {
      return <Loader isLoading />;
  }

  renderSearch() {
      const {
          searchCriteria,
          klevuSearchResult,
          isKlevuSearch,
          klevuLoading,
          onSearchBarChange
      } = this.props;

      return (
      <div block="SearchField" elem="SearchInnerWrapper">
        <div
          block="SearchField"
          elem="SearchIcon"
          role="button"
          tabIndex="0"
          onClick={ this.onIconClick }
          aria-label={ __('Search') }
        />
        <Suspense fallback={ this.renderOverlayFallback() }>
          <SearchOverlay
            isHideOverlay
            clearSearch={ this.clearSearch }
            searchCriteria={ searchCriteria }
            klevuSearchResult={ klevuSearchResult }
            isKlevuSearch={ isKlevuSearch }
            klevuLoading={ klevuLoading }
            onSearchBarChange={ onSearchBarChange }
          />
        </Suspense>
      </div>
      );
  }

  renderSearchIcon() {
      const { showSearch } = this.state;

      if (showSearch) {
          return (
        <div
          block="SearchField"
          elem="CloseIcon"
          role="button"
          tabIndex="0"
          onClick={ this.closeSearch }
          aria-label={ __('Close') }
        />
          );
      }

      return (
      <div
        block="SearchField"
        elem="SearchIcon"
        role="button"
        tabIndex="0"
        onClick={ this.openSearch }
        aria-label={ __('Search') }
      />
      );
  }

  renderDesktopContent() {
      // const { showSearch } = this.state;

      return (
          <>
        { this.renderSearchIcon() }
        <div
          block="SearchField"
          elem="SearchWrapper"
          //   mods={ { isVisible: showSearch } }
        >
          { this.renderSearch() }
        </div>
          </>
      );
  }

  renderMobileContent() {
      const {
          searchCriteria, onSearchBarFocus, isActive, device
      } = this.props;

      if (!device.isMobile) {
          return null;
      }

      const { isPlaceholderVisible } = this.state;

      return (
          <>
        <input
          id="search-field"
          ref={ this.searchBarRef }
          block="SearchField"
          elem="Input"
          onFocus={ onSearchBarFocus }
          onChange={ this.handleChange }
          onKeyDown={ this.onSearchEnterPress }
          value={ searchCriteria }
          mods={ { isActive } }
          autoComplete="off"
          aria-label={ __('Search') }
        />
        <div
          block="SearchField"
          elem="Placeholder"
          mods={ {
              isActive,
              isPlaceholderVisible
          } }
        >
          <span>{ __('Search') }</span>
        </div>
        <Suspense fallback={ this.renderOverlayFallback() }>
          <SearchOverlay
            clearSearch={ this.clearSearch }
            searchCriteria={ searchCriteria }
          />
        </Suspense>
          </>
      );
  }

  returnNothing() {
      return null;
  }

  render() {
      const {
          isActive,
          searchCriteria,
          onSearchBarFocus,
          device,
          klevuSearchResult,
          isKlevuSearch,
          klevuLoading,
          onSearchBarChange
      } = this.props;

      return (
      <ClickOutside
        // eslint-disable-next-line react/jsx-no-bind
        onClick={ () => {
            this.closeSearch();
        } }
      >
        <div className="SearchInputWrapper">
          <input
            type="text"
            placeholder="Search products, brands and health concerns"
            className="SearchInput"
            onFocus={ () => {
                if (device.isMobile) {
                    history.push(appendWithStoreCode('/menu'));
                }
                onSearchBarFocus();
            } }
            onChange={ (e) => {
                if (device.isMobile) {
                    onSearchBarFocus();
                }
                this.handleChange(e);
            } }
            onKeyDown={ this.onSearchEnterPress }
            value={ searchCriteria }
            mods={ { isActive } }
            autoComplete="off"
            aria-label={ __('Search') }
          />
          <div className="SearchIconWrapper">
            <svg
              className="SearchIcon"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={ 2 }
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>

          <div>
            <Suspense fallback={ this.returnNothing() }>
              <SearchOverlay
                onSearchBarChange={ onSearchBarChange }
                clearSearch={ this.clearSearch }
                searchCriteria={ searchCriteria }
                klevuSearchResult={ klevuSearchResult }
                isKlevuSearch={ isKlevuSearch }
                klevuLoading={ klevuLoading }
              />
            </Suspense>
          </div>
        </div>
      </ClickOutside>
      );
  }
}

export default SearchFieldComponent;
