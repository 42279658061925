/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const PDP = 'pdp';
export const POPUP = 'popup';
export const CATEGORY = 'category';
export const CUSTOMER_ACCOUNT = 'customer_account';
export const CUSTOMER_SUB_ACCOUNT = 'customer_sub_account';
export const CUSTOMER_ACCOUNT_PAGE = 'customer_account_page';
export const CUSTOMER_WISHLIST = 'wishlist';
export const HOME_PAGE = 'home';
export const MENU = 'menu';
export const MENU_SUBCATEGORY = 'menu_subcategory';
export const SEARCH = 'search';
export const FILTER = 'filter';
export const CART = 'cart';
export const CART_OVERLAY = 'cart_overlay';
export const CART_EDITING = 'cart_editing';
export const CHECKOUT = 'checkout';
export const CHECKOUT_SUCCESS = 'checkout_success';
export const CHECKOUT_ACCOUNT = 'checkout_account';
export const CMS_PAGE = 'cms-page';
export const MY_ACCOUNT = 'my-account';
export const NO_MATCH = 'no-match';
export const CONTACT_US = 'contact-us';
export const PRODUCT_COMPARE = 'product-compare';
export const LOGIN = 'account/login';
export const REGISTER = 'account/create';
export const FORGOT_PASSWORD = 'account/forgotpassword';
export const SMART_CARD = 'previlege-card/registration';

export const SEARCH_RESULT = {
  meta: {
    layoutId: '',
    layoutType: '',
    noOfResults: 3,
    paginationStartFrom: 0,
    powerdByLogo: '',
    totalResultsFound: 2938,
    typeOfQuery: 'WILDCARD_AND',
    storeBaseCurrency: 'AED',
    notificationCode: 1,
    term: 'r',
    excludeIds: null,
    isPersonalised: false,
    idsUsedForPersonalisation: null,
  },
  result: [
    {
      discount: '',
      hideGroupPrices: '',
      itemGroupId: '',
      freeShipping: '',
      storeBaseCurrency: 'AED',
      price: '42',
      toPrice: '',
      imageUrl:
        'https://d2h6gkedqcjr8m.cloudfront.net/klevu_images/200X200/r/_/r_r_inflatable_round_cushion_su_8202.jpg',
      currency: 'AED',
      inStock: 'yes',
      id: '10027',
      imageHover: '',
      sku: '28672-5536',
      startPrice: '42',
      image:
        'https://d2h6gkedqcjr8m.cloudfront.net/klevu_images/200X200/r/_/r_r_inflatable_round_cushion_su_8202.jpg',
      deliveryInfo: '',
      hideAddToCart: '',
      salePrice: '42.0',
      oldPrice: '42.0',
      swatches: {
        swatch: [],
        lowestPrice: '',
        numberOfAdditionalVariants: '',
      },
      weight: '1.0100',
      klevu_category:
        'KLEVU_PRODUCT;;Rehab And Supports;;Rehab And Supports;Orthopedic Supports ;;Rehab And Supports;Orthopedic Supports ;Seat & Back Support  @ku@kuCategory@ku@',
      totalVariants: '0',
      groupPrices: '',
      url: 'https://www.medisouq.com/r-r-inflatable-round-cushion-su-8202.html',
      name: 'R&R Inflatable Round Cushion SU 8202',
      shortDesc:
        'Inflatable round cushion New multi-chamber and effective weight distribution Easy to inflate and deflate Easy to fold and carry',
      category: 'Rehab And Supports;;Orthopedic Supports;;Seat & Back Support',
      typeOfRecord: 'KLEVU_PRODUCT',
    },
    {
      discount: '',
      hideGroupPrices: '',
      itemGroupId: '',
      freeShipping: '',
      storeBaseCurrency: 'AED',
      price: '362.25',
      toPrice: '',
      imageUrl:
        'https://d2h6gkedqcjr8m.cloudfront.net/klevu_images/200X200/r/_/r_r_decubitus_ulcer_cushion_su-_8407-2.jpg',
      currency: 'AED',
      inStock: 'yes',
      id: '10011',
      imageHover: '',
      sku: '28672-5515',
      startPrice: '362.25',
      image:
        'https://d2h6gkedqcjr8m.cloudfront.net/klevu_images/200X200/r/_/r_r_decubitus_ulcer_cushion_su-_8407-2.jpg',
      deliveryInfo: '',
      hideAddToCart: '',
      salePrice: '362.25',
      oldPrice: '362.25',
      swatches: {
        swatch: [],
        lowestPrice: '',
        numberOfAdditionalVariants: '',
      },
      weight: '3.0000',
      klevu_category:
        'KLEVU_PRODUCT;;Rehab And Supports;;Rehab And Supports;Orthopedic Supports ;;Rehab And Supports;Orthopedic Supports ;Seat & Back Support  @ku@kuCategory@ku@',
      totalVariants: '0',
      groupPrices: '',
      url: 'https://www.medisouq.com/r-r-decubitus-ulcer-cushion-su-8407.html',
      name: 'R&R Decubitus Ulcer Cushion SU- 8407',
      shortDesc:
        'Improves the blood circulation and muscular relaxation Independent gel and air chambers design Made in Taiwan',
      category: 'Rehab And Supports;;Orthopedic Supports;;Seat & Back Support',
      typeOfRecord: 'KLEVU_PRODUCT',
    },
    {
      discount: '',
      hideGroupPrices: '',
      itemGroupId: '',
      freeShipping: '',
      storeBaseCurrency: 'AED',
      price: '33',
      toPrice: '',
      imageUrl:
        'https://d2h6gkedqcjr8m.cloudfront.net/klevu_images/200X200/r/_/r_r_soft_cold_hot_eye_mask-2.jpg',
      currency: 'AED',
      inStock: 'yes',
      id: '9711',
      imageHover: '',
      sku: '28672-5125',
      startPrice: '33',
      image:
        'https://d2h6gkedqcjr8m.cloudfront.net/klevu_images/200X200/r/_/r_r_soft_cold_hot_eye_mask-2.jpg',
      deliveryInfo: '',
      hideAddToCart: '',
      salePrice: '33.0',
      oldPrice: '33.0',
      swatches: {
        swatch: [],
        lowestPrice: '',
        numberOfAdditionalVariants: '',
      },
      weight: '1.0100',
      klevu_category:
        'KLEVU_PRODUCT;;Rehab And Supports;;Rehab And Supports;First Aid Remedies;;Rehab And Supports;First Aid Remedies;Hot & Cold Therapy  @ku@kuCategory@ku@',
      totalVariants: '0',
      groupPrices: '',
      url: 'https://www.medisouq.com/r-r-soft-cold-hot-eye-mask-sp-7206e.html',
      name: 'R&R Soft Cold Hot Eye Mask SP-7206E',
      shortDesc:
        'Reduces crow’s feet and shadows Relieves eye fatigue Soothe puffy eyes',
      category: 'Rehab And Supports;;First Aid Remedies;;Hot & Cold Therapy',
      typeOfRecord: 'KLEVU_PRODUCT',
    },
  ],
  filters: [],
  price: {
    min: '0',
    max: '21500',
    start: '',
    end: '',
  },
  autoComplete: [
    '<b>r</b>oche-posay',
    '<b>r</b>emover',
    '<b>r</b>oyal',
    '<b>r</b>ose',
    '<b>r</b>ub',
  ],
  popularTerm: [],
  pages: [],
  categories: [
    {
      discount: '',
      hideGroupPrices: '',
      itemGroupId: '',
      freeShipping: '',
      storeBaseCurrency: 'USD',
      price: '',
      toPrice: '',
      imageUrl: '',
      currency: 'USD',
      inStock: 'yes',
      id: 'categoryid_364',
      imageHover: '',
      startPrice: '',
      image: '',
      deliveryInfo: '',
      hideAddToCart: '',
      salePrice: '0.0',
      oldPrice: '0.0',
      swatches: {
        swatch: [],
        lowestPrice: '',
        numberOfAdditionalVariants: '',
      },
      weight: '',
      klevu_category: 'KLEVU_CATEGORY  @ku@kuCategory@ku@',
      totalVariants: '0',
      groupPrices: '',
      url: 'https://www.medisouq.com/health-pakages/asthetic/laser-hair-removal',
      name: 'Laser Hair Removal',
      shortDesc: '',
      category: 'Categories',
      typeOfRecord: 'KLEVU_CATEGORY',
    },
    {
      discount: '',
      hideGroupPrices: '',
      itemGroupId: '',
      freeShipping: '',
      storeBaseCurrency: 'USD',
      price: '',
      toPrice: '',
      imageUrl: '',
      currency: 'USD',
      inStock: 'yes',
      id: 'categoryid_462',
      imageHover: '',
      startPrice: '',
      image: '',
      deliveryInfo: '',
      hideAddToCart: '',
      salePrice: '0.0',
      oldPrice: '0.0',
      swatches: {
        swatch: [],
        lowestPrice: '',
        numberOfAdditionalVariants: '',
      },
      weight: '',
      klevu_category: 'KLEVU_CATEGORY  @ku@kuCategory@ku@',
      totalVariants: '0',
      groupPrices: '',
      url: 'https://www.medisouq.com/vitamins-suppliments/sports-nutrition/meal-replacements.html',
      name: 'Protein Bars & Meal Repacements',
      shortDesc: '',
      category: 'Categories',
      typeOfRecord: 'KLEVU_CATEGORY',
    },
    {
      discount: '',
      hideGroupPrices: '',
      itemGroupId: '',
      freeShipping: '',
      storeBaseCurrency: 'USD',
      price: '',
      toPrice: '',
      imageUrl: '',
      currency: 'USD',
      inStock: 'yes',
      id: 'categoryid_526',
      imageHover: '',
      startPrice: '',
      image: '',
      deliveryInfo: '',
      hideAddToCart: '',
      salePrice: '0.0',
      oldPrice: '0.0',
      swatches: {
        swatch: [],
        lowestPrice: '',
        numberOfAdditionalVariants: '',
      },
      weight: '',
      klevu_category: 'KLEVU_CATEGORY  @ku@kuCategory@ku@',
      totalVariants: '0',
      groupPrices: '',
      url: 'https://www.medisouq.com/skin-care/makeup/makeup-remover.html',
      name: 'Makeup Remover',
      shortDesc: '',
      category: 'Categories',
      typeOfRecord: 'KLEVU_CATEGORY',
    },
  ],
  popularProducts: [],
  recentlyViewedProducts: [],
  ranges: [],
  error: { errorMessage: '' },
};
